@import "~bootstrap/scss/bootstrap";
@import "Shadows.scss";

.error-message {
    color: red;
}

.error-input {
    border: 1px solid red;
}

.pagination-container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .pagination-block {
        .label {
            margin-right: 20px;
        }

        display: flex;
        align-items: center;

        .page-count-select {
            align-items: center;
            width: 80px;
            margin-right: 50px;
        }

        width: 100%;
        justify-content: center;
    }

    .pagination {
        margin-bottom: 0;
    }
}

/*BOOTSTRAP*/

.page-item.active .page-link {
    z-index: 0;
}

.sign-in-container {
    .registration-btn-container {
        text-align: center;

        .registration-btn {
            background-color: #42b72a;
            border-color: #42b72a;
            @media (max-width: 576px) {
                width: 100%;
            }
            @media (min-width: 577px) {
                width: 70%;
            }
        }
    }

    hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .my-words-main-title {
        font-size: 40px;
        text-align: center;
    }

    /* xs (<=576px) */
    @media (max-width: 576px) {
        width: 100%;
        left: 0%;
    }

    /* sm (>=576 и <=767) */
    @media (min-width: 576px) and (max-width: 767px) {
        width: 74%;
        left: 13%;
    }

    /* md (>=768 и <=991) */
    @media (min-width: 768px) and (max-width: 991px) {
        width: 50%;
        left: 25%;
    }

    /* lg (>=992 и <=1199) */
    @media (min-width: 992px) and (max-width: 1199px) {
        width: 34%;
        left: 33%;
    }

    /* xl (>=1200) */
    @media (min-width: 1200px) {
        width: 26%;
        left: 37%;
    }

    position: absolute;
    top: 50%;

    margin: -100px auto 0;
}

.logout-nav-link {
    font-weight: 700;
}

.main-page-container {
    h1 {
        text-align: center;
    }

    .main-title {
        font-size: 40px;
        text-align: center;
    }

    /* xs (<=576px) */
    @media (max-width: 576px) {
        width: 100%;
        left: 0%;
    }

    /* sm (>=576 и <=767) */
    @media (min-width: 576px) and (max-width: 767px) {
        width: 100%;
        left: 0%;
    }

    /* md (>=768 и <=991) */
    @media (min-width: 768px) and (max-width: 991px) {
        width: 100%;
        left: 0%;
    }

    /* lg (>=992 и <=1199) */
    @media (min-width: 992px) and (max-width: 1199px) {
        width: 800px;
        left: 0%;
    }

    /* xl (>=1200) */
    @media (min-width: 1200px) {
        width: 800px;
    }

    margin: 0 auto;

    .buttons-group {
        text-align: center;
    }

    .current-zakup-block {
        min-height: 200px;
        background-color: #d3d3d345;
        padding: 10px;
        border-radius: 10px;

        .ranobe-row {
            margin-bottom: 20px;

            .non-editable-ranobe-row {
                position: relative;

                .edit-icon {
                    position: absolute;
                    top: 0px;
                    right: 0px;

                    a {
                        color: #c0c2c5;

                        &:hover {
                            color: #7b7b7b;
                        }
                    }
                }
            }

            .smaller-input {
                font-size: 14px;
                padding: 2px 5px;
            }

            .original-ranobe-title {
                font-size: 10px;
                color: rgb(179, 179, 179);
            }
            .created-new-ditectory-title {
                font-size: 12px;
                color: #15a047;
            }
            .renamed-ranobe-title {
                font-weight: 600;
                color: black;
            }
            .editable-renamed-ranobe-title {
                font-weight: 650;
                color: black;
            }

            .search_highlight {
                font-weight: 600;
                background-color: #42b72a;
            }
        }
    }

    .zakup-histories-block {
        /* xs (<=576px) */
        @media (max-width: 576px) {
            width: 100%;
            left: 0%;
        }

        /* sm (>=576 и <=767) */
        @media (min-width: 576px) and (max-width: 767px) {
            width: 100%;
            left: 0%;
        }

        /* md (>=768 и <=991) */
        @media (min-width: 768px) and (max-width: 991px) {
            width: 100%;
            left: 0%;
        }

        /* lg (>=992 и <=1199) */
        @media (min-width: 992px) and (max-width: 1199px) {
            width: 800px;
            left: 0%;
        }

        /* xl (>=1200) */
        @media (min-width: 1200px) {
            width: 800px;
        }

        margin: auto;

        .zakup-history-block {
            min-height: 130px;
            background-color: #e8e8e845;
            margin-top: 20px;
            border-radius: 10px;
            padding: 10px;
            position: relative;
            border: 1px solid #e7e7e7;

            .created-date {
                font-weight: 600;
                font-size: large;
                text-align: center;
            }

            .ranobes-list {
                margin-top: 10px;

                .move-path {
                    color: gray;
                    font-size: small;
                    margin-bottom: 10px;
                }

                .margin-left-10 {
                    margin-left: 10px !important;
                }
            }

            .icons-ranobe-history-container {
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 10px;
                right: 10px;

                .copy-icon {
                    padding-bottom: 10px;
                    a {
                        color: #1bc274;

                        &:hover {
                            color: #0e7444;
                        }
                    }
                }

                .move-history-icon {
                    padding-bottom: 7px;

                    a {
                        color: #5f6a74;

                        &:hover {
                            color: #1b6ec2;
                        }
                    }
                }
                .logs-icon {
                    padding-bottom: 7px;

                    a {
                        color: #c0c2c5;

                        &:hover {
                            color: #7b7b7b;
                        }
                    }
                }
            }
        }
    }
}
